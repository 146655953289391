$(document).ready(function() {

	function toggleSideForm() {
		$('.side-form-container').toggleClass('side-form-container_open');
		$('.side-btn').toggleClass('side-btn_close');
		$('.side-btn__content_close, .side-btn__content_open').toggleClass('side-btn__content_hide');
	}

	$('.side-form__button').click(toggleSideForm);

	$('body').keyup(function(e) {
		if ($('.side-form-container_open').length && e.which == 27) {
			toggleSideForm();
		}
	});

	// send form
	$('body').on('submit', '.js-validate-form', function(e) {

		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			// $('.js-validate-form--send').prop('disabled', true);
			var fields = $(".js-validate-form .form-control");

			$.ajax({
				type: "POST",
				url: $('.js-validate-form').attr('action'),
				data: $('.js-validate-form').serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.status == "success") {

						// $('.js-validate-form .msg-success').removeClass('hidden');
						$('.js-validate-form input:not(.js-validate-form--send), .js-validate-form textarea').val("");

						// $('.js-validate-form--send').prop('disabled', false);

						$.each(fields, function(index, elem) {
							$(elem).next('.with-errors').text('');
						})
 
						$('.js-validate-form').append('<div style="color: #000; margin-top: 10px; text-align: center; font-size: 17px; font-weight: 400;" class="js-validate-form-text-after-sending-form">' + data.text_end + '</div>');

						setTimeout(function() {
							// $('.js-validate-form .msg-success').addClass('hidden');
							$('.js-validate-form-text-after-sending-form').remove();
							toggleSideForm();
						}, 3000);
					} else {

						var fields_db = [];
						$.each(fields, function(index, elem) {
							fields_db.push($(elem).attr('name'));
						})

						var errorsArr = Object.keys(data.errors);
						for (var i = 0; i < fields_db.length; i++) {
							for (var j = 0; j < errorsArr.length; j++) {
								if (fields_db[i].includes(errorsArr[j])) {
									$.each(fields, function(index, elem) {
										if (elem.name === fields_db[i]) {
											$(elem).parent().removeClass('has-success').addClass('has-error has-danger');
											$(elem).next('.with-errors').text(data.errors[errorsArr[j]]);
										}
									})
								}
							}
						}

						// $('.js-validate-form--send').prop('disabled', false);

					}
				}
			});
		}
		return false;
	});

});
