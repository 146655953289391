$(function(){
  $(".fileuplaodcv").AjaxFileUpload({
    onChange: function(filename){
        $(this).closest('.fileupload').find(".btn-file").addClass("uploading");
    },
    onComplete: function(filename, response) {

      if (response == 'FAILED') { // לא תמיד צריך את זה
        $(this).closest('.fileupload').find(".fileuplaodcvupdate").val(response)
        $(this).closest('.fileupload').find(".txt_up").text(response);
        $(this).closest('.fileupload').find(".btn-file").removeClass("uploading");

      } else {
        $(this).closest('.fileupload').find(".fileuplaodcvupdate").val(response)
        $(this).closest('.fileupload').find(".txt_up").text(filename);
        $(this).closest('.fileupload').find(".btn-file").removeClass("uploading");
      }
       
      }
  });
});
